import { PageComponent } from '@root/pages/Type';
import { Trans } from 'react-i18next';
import styles from './TextSection.module.scss';

const TextSection: PageComponent = () => {
    return (
        <section className={`container ${styles.section}`}>
            <div className={styles.background_diamonds}>
                <h1 className={styles.title}>
                    <Trans i18nKey="textSection.title" ns="landingHome">
                        Greitis ir stabilumas
                    </Trans>
                </h1>
                <p>
                    <Trans i18nKey="textSection.description" ns="landingHome">
                        Naudojame tikrus SSD diskus, kurie užtikrins darbo
                        kokybę. Lorem ipsum dolor sit amet, albucius laboramus
                        ullamcorper pro in, his ei sanctus suscipiantur.
                    </Trans>
                </p>
            </div>
        </section>
    );
};

export default TextSection;
