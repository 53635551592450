import { useCallback, useMemo, useState } from 'react';

const useTab = (eventKeys: Array<string>, initialEventKey: string) => {
    const [activeKey, setActiveKey] = useState<string>(initialEventKey);

    const setActiveKeyWrap = useCallback(
        (eventKey: string) => {
            setActiveKey(eventKey);
        },
        [setActiveKey],
    );
    
    const index = useMemo(() => eventKeys.findIndex((key) => key === activeKey), [eventKeys, activeKey]);

    const setNextActiveKey = useCallback(() => {
        let nextIndex = index + 1;
        if (nextIndex >= eventKeys.length) {
            nextIndex = 0;
        }
        setActiveKey(eventKeys[nextIndex])
    }, [index, eventKeys, setActiveKey]);
    const setPrevActiveKey = useCallback(() => {
        let nextIndex = index - 1;
        if (nextIndex < 0) {
            nextIndex = eventKeys.length - 1;
        }
        setActiveKey(eventKeys[nextIndex])
    }, [index, eventKeys, setActiveKey]);

    return {
        activeKey,
        setActiveKey: setActiveKeyWrap,
        setNextActiveKey,
        setPrevActiveKey,
    };
};

export default useTab;
