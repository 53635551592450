// TOOD: Add image alt tag.
/* eslint-disable jsx-a11y/alt-text */
import { Trans } from 'react-i18next';
import { PageComponent } from '@root/pages/Type';
import styles from './AnimationSection.module.scss';
import BackgroundTop from './background-bottom-second.svg';
import AnimationRenderSvg from './AnimationRenderSvg';
import sunPng from '@static/img/sun.png';
import sunBgJpg from '@static/img/sun-background.jpg';
import Image from 'next/image';

const AnimationSection: PageComponent = () => {
    return (
        <section className={styles.section}>
            <div className={styles.top_container}>
                <div className={styles.top_inner}>
                    <ul>
                        <li>
                            <img src="/static/img/brands/hp.svg" />
                        </li>
                        <li>
                            <img src="/static/img/brands/dell.svg" />
                        </li>
                        <li>
                            <img src="/static/img/brands/intel.svg" />
                        </li>
                    </ul>
                    <AnimationRenderSvg />
                </div>
                <div className={styles.img_bg}>
                    <BackgroundTop />
                </div>
            </div>
            <div className={styles.text_container}>
                <h2>
                    <Trans i18nKey="animationSection.title" ns="landingHome">
                        Aukšto taktinio dažnio CPU
                    </Trans>
                </h2>
                <p>
                    <Trans
                        i18nKey="animationSection.description"
                        ns="landingHome"
                    >
                        Naudojame 3GHz+ procesorius, kurie ypač tinka single
                        core aplikacijomis. Lorem ipsum dolor sit amet, albucius
                        laboramus ullamcorper pro in, his ei sanctus
                        suscipiantur.
                    </Trans>
                </p>
            </div>
            <div className={styles.bottom_container}>
                <div className={styles.bottom_inner}>
                    <div className={styles.price_container}>
                        <div className={styles.price_inner}>
                            <div>
                                <Trans
                                    i18nKey="animationSection.ghzAmount"
                                    ns="landingHome"
                                >
                                    <span>
                                        3<small>GHz</small>
                                    </span>
                                    ir daugiau
                                </Trans>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.img_bg_container}>
                    <img src="/static/img/background-bottom.svg" />
                </div>
            </div>
            <div className={styles.sun_container}>
                <div className={styles.sun_image}>
                    <Image
                        src={sunPng}
                        width={1710}
                        height={1136}
                        quality={65}
                    />
                </div>
                <div
                    className={`${styles.sun_image_animation} d-none d-lg-block`}
                />
                <div className={styles.sun_background}>
                    <Image
                        src={sunBgJpg}
                        layout="fill"
                    />
                </div>
            </div>
        </section>
    );
};

export default AnimationSection;
