import { Nav } from 'react-bootstrap';
import NavContext from 'react-bootstrap/NavContext';
import { makeEventKey } from 'react-bootstrap/SelectableContext';
import React, { useContext } from 'react';
import { NavLinkProps as BootstrapNavLinkProps } from 'react-bootstrap/NavLink';
import classNames from 'classnames';
import { PageComponent } from '@root/pages/Type';

type NavLinkProps = BootstrapNavLinkProps & {
    activeClass?: string;
    eventKey?: string;
};

const NavLink: PageComponent<NavLinkProps> = ({
    activeClass,
    className,
    eventKey,
    active,
    href,
    ...props
}) => {
    let navKey: string | null = null;
    if (eventKey) {
        navKey = makeEventKey(eventKey, href);
    }
    const navContext = useContext(NavContext);
    let isActive = active;
    if (navContext) {
        isActive =
            active == null && navKey != null
                ? navContext.activeKey === navKey
                : active;
    }
    return (
        <Nav.Link
            {...props}
            className={classNames(className, isActive && activeClass)}
            eventKey={eventKey}
        />
    );
};

export default NavLink;
