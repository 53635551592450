import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Page, PageComponent } from '@root/pages/Type';
import getConfig from 'next/config';
import { RouteHref } from '@root/router';
import ni18nConfig from '../../ni18n.config';

type withTitleOptions = {
    default: string;
    translateKey: string;
    namespace: string;
    route?: string;
    keywordsKey?: string;
    descriptionKey?: string;
};

const AlternativeRoute: React.FC<{
    route?: string;
    language: string;
}> = ({ route, language }) => {
    const languages = ni18nConfig.supportedLngs;
    const { publicRuntimeConfig } = getConfig();
    if (!route || !languages) return null;
    return (
        <>
            {languages
                .filter((lang) => lang != language)
                .map((lang) => {
                    const path = RouteHref(route, lang);
                    return (
                        <Helmet key={path}>
                            <link
                                rel="alternate"
                                hrefLang={lang}
                                href={`${publicRuntimeConfig.HOST_URL}${path}`}
                            />
                        </Helmet>
                    );
                })}
        </>
    );
};

const Keywords: PageComponent<{ namespace: string; keywordsKey?: string }> = ({
    namespace,
    keywordsKey,
}) => {
    const { t } = useTranslation(namespace);
    if (!keywordsKey) return null;
    return (
        <Helmet>
            <meta name="keywords" content={t(keywordsKey)} />
        </Helmet>
    );
};

const withTitle = <P extends {} = {}>(
    options: withTitleOptions,
    WrappedComponent: PageComponent<P> | Page<P>,
): PageComponent<P> => {
    const Wrapper: PageComponent<P> = (props) => {
        const { t, i18n } = useTranslation(options.namespace);
        return (
            <>
                <Helmet>
                    <html lang={i18n.language} />
                    <meta name="language" content={i18n.language} />
                    <title>
                        {t(options.translateKey, {
                            defaultValue: options.default,
                        })}
                    </title>
                    <meta
                        name="copyright"
                        content={t('copyright', {
                            ns: 'common',
                            date: new Date().getFullYear() ,
                        })}
                    />
                </Helmet>
                <Keywords
                    namespace={options.namespace}
                    keywordsKey={options.keywordsKey}
                />
                {options.descriptionKey ? (
                    <Helmet>
                        <meta
                            name="description"
                            content={t(options.descriptionKey)}
                        />
                    </Helmet>
                ) : null}
                <AlternativeRoute
                    route={options.route}
                    language={i18n.language}
                />
                <WrappedComponent {...props} />
            </>
        );
    };
    Wrapper.displayName = `withTitle(${
        WrappedComponent.displayName || WrappedComponent.name || 'Component'
    })`;
    return Wrapper;
};

export default withTitle;
