/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// TODO: Add image alt tag.
/* eslint-disable jsx-a11y/alt-text */
import { PageComponent } from '@root/pages/Type';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import { useState } from 'react';
import Link from '@components/Link';
import styles from './ServicesSection.module.scss';
import { default as LinkIcon } from '@static/img/icons/link.svg';
import vpsSelectedPng from '@static/img/icons/services/vps-selected.png';
import vdsSelectedPng from '@static/img/icons/services/vds-selected.png';
import dsSelectedPng from '@static/img/icons/services/ds-selected.png';
import colocationSvg from '@static/img/icons/services/colocation.svg?url';
import Image from 'next/image';

const ServicesSection: PageComponent = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const onClickNext = () => {
        const nextIndex = activeIndex + 1;
        setActiveIndex(nextIndex <= 3 ? nextIndex : 0);
    };
    const onClickPrev = () => {
        const nextIndex = activeIndex - 1;
        setActiveIndex(nextIndex >= 0 ? nextIndex : 3);
    };

    return (
        <section className={styles.section}>
            <div className={styles.main_services}>
                <div
                    className={classNames({
                        [styles.active]: activeIndex === 0,
                    })}
                >
                    <span className={styles.prev} onClick={onClickPrev} />
                    <span className={styles.next} onClick={onClickNext} />
                    <div>
                        <Link href="landing_services_vps">
                            <a className={styles.clear}>
                                <p className={styles.img_container}>
                                    <Image
                                        src={vpsSelectedPng}
                                        width={228}
                                        height={228}
                                    />
                                </p>
                            </a>
                        </Link>
                        <h3>
                            <Trans
                                i18nKey="servicesSection.vps.title"
                                ns="landingHome"
                            >
                                Virtualus Serveris
                            </Trans>
                        </h3>
                        <p>
                            <Trans
                                i18nKey="servicesSection.vps.description"
                                ns="landingHome"
                            >
                                Tai pigi dedikuoto serverio alternatyva, paremta
                                debesų technologija
                            </Trans>
                        </p>
                        <Link href="landing_services_vps">
                            <a>
                                <LinkIcon />
                            </a>
                        </Link>
                    </div>
                </div>
                <div
                    className={classNames({
                        [styles.active]: activeIndex === 1,
                    })}
                >
                    <span className={styles.prev} onClick={onClickPrev} />
                    <span className={styles.next} onClick={onClickNext} />
                    <div>
                        <Link href="landing_services_vds">
                            <a className={styles.clear}>
                                <p className={styles.img_container}>
                                    <Image
                                        src={vdsSelectedPng}
                                        width={228}
                                        height={228}
                                    />
                                </p>
                            </a>
                        </Link>
                        <h3>
                            <Trans
                                i18nKey="servicesSection.vds.title"
                                ns="landingHome"
                            >
                                Virtualus Dedikuotas Serveris
                            </Trans>
                        </h3>
                        <p>
                            <Trans
                                i18nKey="servicesSection.vds.description"
                                ns="landingHome"
                            >
                                VDS yra pigesne dedikuoto serverio alternatyva
                            </Trans>
                        </p>
                        <Link href="landing_services_vds">
                            <a>
                                <LinkIcon />
                            </a>
                        </Link>
                    </div>
                </div>
                <div
                    className={classNames({
                        [styles.active]: activeIndex === 3,
                    })}
                >
                    <span className={styles.prev} onClick={onClickPrev} />
                    <span className={styles.next} onClick={onClickNext} />
                    <div>
                        <Link href="landing_services_colocation">
                            <a className={styles.clear}>
                                <p
                                    className={classNames(
                                        styles.img_container,
                                        styles.smaller,
                                    )}
                                >
                                    <Image
                                        src={colocationSvg}
                                        width={185}
                                        height={185}
                                    />
                                </p>
                            </a>
                        </Link>
                        <h3>
                            <Trans
                                i18nKey="servicesSection.colocation.title"
                                ns="landingHome"
                            >
                                Serveriu kolokacija
                            </Trans>
                        </h3>
                        <p>
                            <Trans
                                i18nKey="servicesSection.colocation.description"
                                ns="landingHome"
                            >
                                Jūsų nuosavo serverio patalpinimas mūsų duomenų
                                centre
                            </Trans>
                        </p>
                        <Link href="landing_services_colocation">
                            <a>
                                <LinkIcon />
                            </a>
                        </Link>
                    </div>
                </div>
                <div
                    className={classNames({
                        [styles.active]: activeIndex === 2,
                    })}
                >
                    <span className={styles.prev} onClick={onClickPrev} />
                    <span className={styles.next} onClick={onClickNext} />
                    <div>
                        <Link href="landing_services_ds">
                            <a className={styles.clear}>
                                <p className={styles.img_container}>
                                    <Image
                                        src={dsSelectedPng}
                                        width={228}
                                        height={228}
                                    />
                                </p>
                            </a>
                        </Link>
                        <h3>
                            <Trans
                                i18nKey="servicesSection.ds.title"
                                ns="landingHome"
                            >
                                Dedikuotas Serveris
                            </Trans>
                        </h3>
                        <p>
                            <Trans
                                i18nKey="servicesSection.ds.description"
                                ns="landingHome"
                            >
                                Atskiras fizinis serveris su vaisais dedikuotais
                                resursais (RAM, CPU, SSD ir pan.)
                            </Trans>
                        </p>
                        <Link href="landing_services_ds">
                            <a>
                                <LinkIcon />
                            </a>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServicesSection;
