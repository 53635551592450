/* eslint-disable jsx-a11y/alt-text */
// TOOD: implement this types.
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { PageComponent } from '@root/pages/Type';
import styles from './AnimationSection.module.scss';
import animationPart1 from '@static/img/animation-part-1.png';
import animationPart3Png from '@static/img/animation-part-3.png';
import animationPart3Svg from '@static/img/animation-part-3.svg?url';
import animationPart2 from '@static/img/animation-part-2.svg?url';
import Image from 'next/image';

const AnimationRenderSvg: PageComponent = () => {
    return (
        <>
            <div className={styles.img_container}>
                <div className={styles.img_part_1}>
                    <Image src={animationPart1} width={535} height={500} />
                </div>
                <div className={styles.img_part_3_png}>
                    <Image src={animationPart3Png} width={572} height={573} />
                </div>
                <div className={styles.img_part_3_svg}>
                    <Image src={animationPart3Svg} width={572} height={573} />
                </div>
                <div className={styles.img_part_2}>
                    <Image src={animationPart2} width={195} height={195} />
                </div>
            </div>
        </>
    );
};

export default AnimationRenderSvg;
