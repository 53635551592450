// TODO: Add image alt tag.
/* eslint-disable jsx-a11y/alt-text */
import { MainLayout } from '@components/layouts/Index';
import Header from '@components/Header';
import Footer from '@components/Footer';
import { Page, PageComponent } from '@pages/Type';
import withLayout from '@root/utils/withLayout';
import withCheckAuth from '@root/utils/withCheckAuth';
import MainSection from './MainSection';
import ServicesSection from './ServicesSection';
import TextSection from './TextSection';
import AnimationSection from './AnimationSection';
import PanelSection from './PanelSection';
import FeaturesSection from './FeaturesSection';
import FooterStyles from './Footer.module.scss';
import withTitle from '@root/utils/withTitle';

const FooterRenderBottom: PageComponent = () => (
    <>
        <div className={FooterStyles.footer_fix_features_background_container}>
            <img
                src="/static/img/background-bottom-second.svg"
                className={FooterStyles.footer_fix_features_background}
            />
        </div>
    </>
);

const Index: Page = () => {
    return (
        <>
            <Header />
            <MainSection />
            <ServicesSection />
            <TextSection />
            <AnimationSection />
            <PanelSection />
            <FeaturesSection />
            <Footer
                toTop={false}
                renderTopButton
                hasContactForm
                renderBottom={<FooterRenderBottom />}
            />
        </>
    );
};

export const getServerSideProps = withCheckAuth(async () => {
    return {
        props: {},
        revalidate: 3600, // In seconds
    };
});

export default withTitle(
    {
        translateKey: 'pageTitle',
        keywordsKey: 'pageKeywords',
        descriptionKey: 'pageDescription',
        default: 'Home page',
        namespace: 'landingHome',
        route: 'landing_home'
    },
    withLayout(MainLayout, Index),
);
