// TODO: Add image alt tag.
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Trans } from 'react-i18next';
import { useState } from 'react';
import classNames from 'classnames';
import { PageComponent } from '@root/pages/Type';
import styles from './FeaturesSection.module.scss';

const FeaturesSection: PageComponent = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const onClickNext = () => {
        const nextIndex = activeIndex + 1;
        setActiveIndex(nextIndex <= 3 ? nextIndex : 0);
    };
    const onClickPrev = () => {
        const nextIndex = activeIndex - 1;
        setActiveIndex(nextIndex >= 0 ? nextIndex : 3);
    };
    return (
        <section className={styles.section}>
            <h1 className={styles.title}>
                <Trans i18nKey="featuresSection.title" ns="landingHome">
                    Privalumai
                </Trans>
            </h1>
            <p className={styles.desc}>
                <Trans i18nKey="featuresSection.description" ns="landingHome">
                    Lengvai bei greitai valdykite savo serverius
                </Trans>
            </p>

            <div className={styles.background}>
                <div className={styles.background_services}>
                    <div
                        className={`${styles.main_container} ${styles.background_diamonds} container`}
                    >
                        <div className={styles.main_services}>
                            <div
                                className={classNames({
                                    [styles.active]: activeIndex === 0,
                                })}
                            >
                                <span
                                    className={styles.prev}
                                    onClick={onClickPrev}
                                />
                                <span
                                    className={styles.next}
                                    onClick={onClickNext}
                                />
                                <div>
                                    <div className={styles.icon}>
                                        <img src="/static/img/icons/backup.svg" />
                                    </div>
                                    <h3>
                                        <Trans
                                            i18nKey="featuresSection.backups.title"
                                            ns="landingHome"
                                        >
                                            Backup'ai
                                        </Trans>
                                    </h3>
                                    <p>
                                        <Trans
                                            i18nKey="featuresSection.backups.description"
                                            ns="landingHome"
                                        >
                                            Tai pigi dedikuoto serverio
                                            alternatyva, paremta debesų
                                            technologija
                                        </Trans>
                                    </p>
                                </div>
                            </div>
                            <div
                                className={classNames({
                                    [styles.active]: activeIndex === 1,
                                })}
                            >
                                <span
                                    className={styles.prev}
                                    onClick={onClickPrev}
                                />
                                <span
                                    className={styles.next}
                                    onClick={onClickNext}
                                />
                                <div>
                                    <div className={styles.icon}>
                                        <img src="/static/img/icons/thunder-alt.svg" />
                                    </div>
                                    <h3>
                                        <Trans
                                            i18nKey="featuresSection.fastAcitivation.title"
                                            ns="landingHome"
                                        >
                                            Žaibiškas aktyvavimas
                                        </Trans>
                                    </h3>
                                    <p>
                                        <Trans
                                            i18nKey="featuresSection.fastAcitivation.description"
                                            ns="landingHome"
                                        >
                                            Serveris aktyvuojamas per kelias
                                            minutes, bet kurio paros metu.
                                        </Trans>
                                    </p>
                                </div>
                            </div>
                            <div
                                className={classNames({
                                    [styles.active]: activeIndex === 2,
                                })}
                            >
                                <span
                                    className={styles.prev}
                                    onClick={onClickPrev}
                                />
                                <span
                                    className={styles.next}
                                    onClick={onClickNext}
                                />
                                <div>
                                    <div className={styles.icon}>
                                        <img src="/static/img/icons/speedometer.svg" />
                                    </div>
                                    <h3>
                                        <Trans
                                            i18nKey="featuresSection.highQualityHardware.title"
                                            ns="landingHome"
                                        >
                                            Kokybiška įranga
                                        </Trans>
                                    </h3>
                                    <p>
                                        <Trans
                                            i18nKey="featuresSection.highQualityHardware.description"
                                            ns="landingHome"
                                        >
                                            Mes naudojame tik originalią ir
                                            naujausią įrangą.
                                        </Trans>
                                    </p>
                                </div>
                            </div>
                            <div
                                className={classNames({
                                    [styles.active]: activeIndex === 3,
                                })}
                            >
                                <span
                                    className={styles.prev}
                                    onClick={onClickPrev}
                                />
                                <span
                                    className={styles.next}
                                    onClick={onClickNext}
                                />
                                <div>
                                    <div className={styles.icon}>
                                        <img src="/static/img/icons/money-back.svg" />
                                    </div>
                                    <h3>
                                        <Trans
                                            i18nKey="featuresSection.root.title"
                                            ns="landingHome"
                                        >
                                            "Root" prieiga
                                        </Trans>
                                    </h3>
                                    <p>
                                        <Trans
                                            i18nKey="featuresSection.root.description"
                                            ns="landingHome"
                                        >
                                            Su kiekvienu serveriu yra
                                            suteikiamos visos administravimo
                                            paslaugos.
                                        </Trans>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img
                    src="/static/img/background-bottom-second.svg"
                    className={styles.img_bg}
                />
            </div>
        </section>
    );
};

export default FeaturesSection;
