// TODO: Add image alt tag.
/* eslint-disable jsx-a11y/alt-text */
import { PageComponent } from '@pages/Type';
import { Trans } from 'react-i18next';
import styles from './MainSection.module.scss';
// import HomeTopAnimation from './HomeTopAnimation';
import { LinkAuthenticated } from '@root/components/HeaderContext';
import { useUser } from '@root/utils/useUser';
import bottomBgSvg from '@static/img/background-bottom.svg?url';
import top2Png from '@static/img/home-top-2.png';
import top3Svg from '@static/img/home-top-3.svg?url';
import top4Svg from '@static/img/home-top-4.svg?url';
import top5Svg from '@static/img/home-top-5.svg?url';
import top6Svg from '@static/img/home-top-6.svg?url';
import topBgPng from '@static/img/home-top-background.png';
import topLineSvg from '@static/img/home-top-line.svg?url';
import Image from 'next/image';
import Link from '@root/components/Link';

const RegisterButton = () => {
    const user = useUser();
    if (user !== null) return null;
    return (
        <LinkAuthenticated href="ui_order" passHref showRegistration>
            <a className={`btn btn-primary ${styles.btn_primary}`}>
                <Trans i18nKey="mainSection.buttonRegister" ns="landingHome">
                    Registruotis
                </Trans>
            </a>
        </LinkAuthenticated>
    );
};

const OrderButton = () => {
    const user = useUser();
    if (user === null) return null;
    return (
        <Link href="ui_order" passHref>
            <a className={`btn btn-primary ${styles.btn_primary}`}>
                <Trans i18nKey="mainSection.buttonOrder" ns="landingHome">
                    Užsakyti dabar
                </Trans>
            </a>
        </Link>
    );
};

const MainSection: PageComponent = () => {
    return (
        <section className={styles.section}>
            <div className={`container ${styles.container}`}>
                <div className={styles.text}>
                    <h1>
                        <Trans i18nKey="mainSection.title" ns="landingHome">
                            Serverių sprendimai
                        </Trans>
                    </h1>
                    <h2>
                        <Trans
                            i18nKey="mainSection.description"
                            ns="landingHome"
                        >
                            Greitis. Stabilumas. Kokybė.
                        </Trans>
                    </h2>
                    <OrderButton />
                    <RegisterButton />
                </div>
            </div>

            <div className={styles.bottom_container}>
                <div className={styles.section_extra}>
                    <div className={styles.img_4}>
                        <Image src={top4Svg} width={294} height={294} />
                    </div>
                    {/* <HomeTopAnimation /> */}
                    <div className={styles.img_1}>
                        <Image
                            src={topBgPng}
                            // height={519}
                            // width={3264}
                            layout="fill"
                            quality={95}
                            objectFit="cover"
                            objectPosition="center"
                        />
                    </div>
                    <div className={styles.img_1_1}>
                        <Image
                            src={topLineSvg}
                            // height={225}
                            // width={3276}
                            layout="fill"
                            objectFit="cover"
                            objectPosition="center"
                        />
                    </div>
                    <div className={styles.img_2}>
                        <Image src={top2Png} height={813} width={813} />
                    </div>
                    <div className={styles.img_3}>
                        <Image src={top3Svg} width={125} height={125} />
                    </div>
                    <div className={styles.img_5}>
                        <Image src={top5Svg} width={402} height={424} />
                    </div>
                    <div className={styles.img_6}>
                        <Image src={top6Svg} width={122} height={122} />
                    </div>
                </div>
                <div className={styles.bottom_inner}>
                    <div className={styles.img_bg}>
                        <Image
                            src={bottomBgSvg}
                            width={5758}
                            height={1807}
                            layout="fixed"
                        />
                    </div>
                </div>
            </div>
            <div className={styles.background} />
        </section>
    );
};

export default MainSection;
