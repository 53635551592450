// TODO: Add image alt tag.
/* eslint-disable jsx-a11y/alt-text */
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import NavLink from '@root/components/NavLink';
import { PageComponent } from '@root/pages/Type';
import styles from './PanelSection.module.scss';
import { RootState } from '@root/store/types';
import { useSelector } from 'react-redux';
import Image from 'next/image';
import useTab from '../useTab';

import ssOverviewLtPng from '@static/img/screenshots/ss-overview-lt.png';
import ssOverviewMobileLtPng from '@static/img/screenshots/ss-overview-mobile-lt.png';
import ssOverviewEnPng from '@static/img/screenshots/ss-overview-en.png';
import ssOverviewMobileEnPng from '@static/img/screenshots/ss-overview-mobile-en.png';

import ssOsLtPng from '@static/img/screenshots/ss-os-lt.png';
import ssOsMobileLtPng from '@static/img/screenshots/ss-os-mobile-lt.png';
import ssOsEnPng from '@static/img/screenshots/ss-os-en.png';
import ssOsMobileEnPng from '@static/img/screenshots/ss-os-mobile-en.png';

import ssResourcesLtPng from '@static/img/screenshots/ss-resources-lt.png';
import ssResourcesMobileLtPng from '@static/img/screenshots/ss-resources-mobile-lt.png';
import ssResourcesEnPng from '@static/img/screenshots/ss-resources-en.png';
import ssResourcesMobileEnPng from '@static/img/screenshots/ss-resources-mobile-en.png';

import ssGraphsLtPng from '@static/img/screenshots/ss-graphs-lt.png';
import ssGraphsMobileLtPng from '@static/img/screenshots/ss-graphs-mobile-lt.png';
import ssGraphsEnPng from '@static/img/screenshots/ss-graphs-en.png';
import ssGraphsMobileEnPng from '@static/img/screenshots/ss-graphs-mobile-en.png';

const PanelSection: PageComponent = () => {
    const currentLanguage = useSelector(
        (state: RootState) => state.i18n.currentLanguage,
    );
    const { activeKey, setActiveKey, setNextActiveKey, setPrevActiveKey } =
        useTab(['first', 'second', 'third', 'fourd'], 'first');
    return (
        <section className={`${styles.section_control_panel}`}>
            <h1 className={styles.title}>
                <Trans i18nKey="panelSection.title" ns="landingHome">
                    Intuityvi valdymo panele
                </Trans>
            </h1>
            <p className={styles.desc}>
                <Trans i18nKey="panelSection.description" ns="landingHome">
                    Lengvai bei greitai valdykite savo serverius.
                </Trans>
            </p>
            <div className="container">
                <Tab.Container
                    defaultActiveKey="first"
                    onSelect={(eventKey) => eventKey && setActiveKey(eventKey)}
                    activeKey={activeKey}
                >
                    <Row className={styles.row}>
                        <Col
                            md={6}
                            lg={5}
                            xl={4}
                            className="ml-xl-auto d-none d-md-flex"
                        >
                            <Nav
                                variant="pills"
                                className={`flex-column ${styles.nav_pills}`}
                            >
                                <Nav.Item className={styles.nav_item}>
                                    <NavLink
                                        eventKey="first"
                                        className={`${styles.nav_link}`}
                                        activeClass={styles.nav_link_active}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="panelSection.firstSlideTitle"
                                                ns="landingHome"
                                            >
                                                Serverio informacija ir valdymas
                                            </Trans>
                                        </span>
                                    </NavLink>
                                </Nav.Item>
                                <Nav.Item className={styles.nav_item}>
                                    <NavLink
                                        eventKey="second"
                                        className={`${styles.nav_link}`}
                                        activeClass={styles.nav_link_active}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="panelSection.secondSlideTitle"
                                                ns="landingHome"
                                            >
                                                Operacinės sistemos perrašymas
                                            </Trans>
                                        </span>
                                    </NavLink>
                                </Nav.Item>
                                <Nav.Item className={styles.nav_item}>
                                    <NavLink
                                        eventKey="third"
                                        className={`${styles.nav_link}`}
                                        activeClass={styles.nav_link_active}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="panelSection.thirdSlideTitle"
                                                ns="landingHome"
                                            >
                                                Plano bei papildomų resursų
                                                keitimas
                                            </Trans>
                                        </span>
                                    </NavLink>
                                </Nav.Item>
                                <Nav.Item className={styles.nav_item}>
                                    <NavLink
                                        eventKey="fourd"
                                        className={`${styles.nav_link}`}
                                        activeClass={styles.nav_link_active}
                                    >
                                        <span>
                                            <Trans
                                                i18nKey="panelSection.fourdSlideTitle"
                                                ns="landingHome"
                                            >
                                                Rezervines duomenų kopijos
                                            </Trans>
                                        </span>
                                    </NavLink>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={12} md={6}>
                            <Tab.Content className={styles.tab_content}>
                                <span
                                    className={styles.prev}
                                    onClick={setPrevActiveKey}
                                />
                                <span
                                    className={styles.next}
                                    onClick={setNextActiveKey}
                                />
                                <Tab.Pane
                                    eventKey="first"
                                    className={styles.tab_pane}
                                >
                                    <div className="d-none d-md-block">
                                        {currentLanguage == 'lt' && (
                                            <Image
                                                src={ssOverviewLtPng}
                                                width={950}
                                                height={661}
                                                layout="fixed"
                                            />
                                        )}
                                        {currentLanguage == 'en' && (
                                            <Image
                                                src={ssOverviewEnPng}
                                                width={950}
                                                height={661}
                                                layout="fixed"
                                            />
                                        )}
                                    </div>
                                    <div className="d-md-none">
                                        {currentLanguage == 'lt' && (
                                            <Image
                                                src={ssOverviewMobileLtPng}
                                                width={251}
                                                height={463}
                                            />
                                        )}
                                        {currentLanguage == 'en' && (
                                            <Image
                                                src={ssOverviewMobileEnPng}
                                                width={251}
                                                height={463}
                                            />
                                        )}
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane
                                    eventKey="second"
                                    className={styles.tab_pane}
                                >
                                    <div className="d-none d-md-block">
                                        {currentLanguage == 'lt' && (
                                            <Image
                                                src={ssOsLtPng}
                                                width={950}
                                                height={661}
                                                layout="fixed"
                                            />
                                        )}
                                        {currentLanguage == 'en' && (
                                            <Image
                                                src={ssOsEnPng}
                                                width={950}
                                                height={661}
                                                layout="fixed"
                                            />
                                        )}
                                    </div>
                                    <div className="d-md-none">
                                        {currentLanguage == 'lt' && (
                                            <Image
                                                src={ssOsMobileLtPng}
                                                width={251}
                                                height={463}
                                            />
                                        )}
                                        {currentLanguage == 'en' && (
                                            <Image
                                                src={ssOsMobileEnPng}
                                                width={251}
                                                height={463}
                                            />
                                        )}
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane
                                    eventKey="third"
                                    className={styles.tab_pane}
                                >
                                    <div className="d-none d-md-block">
                                        {currentLanguage == 'lt' && (
                                            <Image
                                                src={ssResourcesLtPng}
                                                width={950}
                                                height={661}
                                                layout="fixed"
                                            />
                                        )}
                                        {currentLanguage == 'en' && (
                                            <Image
                                                src={ssResourcesEnPng}
                                                width={950}
                                                height={661}
                                                layout="fixed"
                                            />
                                        )}
                                    </div>
                                    <div className="d-md-none">
                                        {currentLanguage == 'lt' && (
                                            <Image
                                                src={ssResourcesMobileLtPng}
                                                width={251}
                                                height={463}
                                            />
                                        )}
                                        {currentLanguage == 'en' && (
                                            <Image
                                                src={ssResourcesMobileEnPng}
                                                width={251}
                                                height={463}
                                            />
                                        )}
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane
                                    eventKey="fourd"
                                    className={styles.tab_pane}
                                >
                                    <div className="d-none d-md-block">
                                        {currentLanguage == 'lt' && (
                                            <Image
                                                src={ssGraphsLtPng}
                                                width={950}
                                                height={661}
                                                layout="fixed"
                                            />
                                        )}
                                        {currentLanguage == 'en' && (
                                            <Image
                                                src={ssGraphsEnPng}
                                                width={950}
                                                height={661}
                                                layout="fixed"
                                            />
                                        )}
                                    </div>
                                    <div className="d-md-none">
                                        {currentLanguage == 'lt' && (
                                            <Image
                                                src={ssGraphsMobileLtPng}
                                                width={251}
                                                height={463}
                                            />
                                        )}
                                        {currentLanguage == 'en' && (
                                            <Image
                                                src={ssGraphsMobileEnPng}
                                                width={251}
                                                height={463}
                                            />
                                        )}
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
            <img
                src="/static/img/control-panel-background-second.svg"
                className={styles.left_shape}
            />
        </section>
    );
};

export default PanelSection;
